import {
  ClientDetail,
  ClientProduct,
  File,
  DeliveryAddress,
  User,
  Administrator,
  Employee,
  Result,
  Department,
  ClientsRegistration,
  ExportsBulk,
} from '@odiupsk/up-api-client'
import { DeepPartial } from 'ts-essentials'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { select } from '@lingui/macro'
import { clientsApi } from '.'
import { get } from '../utils/get'
import { logError, logSuccess } from './utils'
import {
  errorDuringFetch,
  itemWasSaved,
  errorDuringSave,
  itemWasDeleted,
  errorDuringDelete,
} from '../utils/i18n/alerts'
import { maxPerPage } from '../utils/maxPerPage'
import { i18n } from '../utils/intl'
import { CLIENT_EMPLOYEES_BULK_OPERATIONS } from '../../config/constants'
import { PatchEntity } from '../utils/types'
import { downloadBlob } from '../utils/downloadToDisk'

export enum EmployeesImportTemplateActionType {
  Import = 1,
  ActivateDeactivate = 2,
  Remove = 3,
}

export class ApiClientsRepository {
  // TODO: it would be nice to replace all API calls in codebase with these

  static async fetchBranch(clientId: string) {
    try {
      const res = await clientsApi.getBranches(clientId)
      // this SHOULD be always just one, they say :-)
      const branch = get(res.data.data, 0)
      if (!branch) throw Error('Missing branch!')
      return branch
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchDeliveryAddresses(clientId: string, branchId: string, params: {} = {}) {
    try {
      const res = await clientsApi.postDeliveryAddresses(clientId, branchId, {
        ...{ per_page: maxPerPage, sort: 'name' },
        ...params,
      })
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchDeliveryAddress(clientId: string, branchId: string, id: string) {
    try {
      const res = await clientsApi.getDeliveryAddress(clientId, branchId, id)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async importDeliveryAddressesFromFile(
    clientId: string,
    branchId: string,
    body: File,
    showAlertOnError: boolean = true
  ) {
    try {
      await clientsApi.postImportFromTemplateClientDeliveryAddresses(clientId, branchId, body)
      logSuccess(itemWasSaved)
    } catch (err) {
      if (showAlertOnError) {
        logError(err, errorDuringSave)
      }

      throw err
    }
  }

  static async fetchDepartments(clientId: string, config?: AxiosRequestConfig, params: {} = {}) {
    try {
      const res = await clientsApi.getDepartments(
        clientId,
        config || {
          params: { ...{ per_page: maxPerPage, sort: 'name' }, ...params },
        }
      )
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchDepartment(clientId: string, id: string) {
    try {
      const res = await clientsApi.getDepartment(clientId, id)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async deleteDepartment(clientId: string, id: string) {
    try {
      const res = await clientsApi.deleteDepartment(clientId, id)
      logSuccess(itemWasDeleted)
      return res.data
    } catch (err) {
      logError(err, errorDuringDelete)
      throw err
    }
  }

  static async patchDepartment(clientId: string, id: string, body: Partial<Department>) {
    try {
      const res = await clientsApi.patchDepartment(clientId, id, body)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async saveDepartment(clientId: string, id: string, body: Department) {
    try {
      const res = await clientsApi.putDepartment(clientId, id, body)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async fetchNotifications(clientId: string) {
    try {
      const res = await clientsApi.getNotifications(clientId)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async updateNotifications(clientId: string, body: any) {
    try {
      const res = await clientsApi.postNotifications(clientId, body)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchCards(clientId: string, params?: any) {
    try {
      let paramsLocal: any = { ...params }
      if (paramsLocal?.filter) {
        paramsLocal = {
          ...paramsLocal,
          filter: paramsLocal.filter.substring(1, paramsLocal.filter.length - 1),
        }
      }

      if (paramsLocal?.desc) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields_desc: paramsLocal.desc,
        }
        delete paramsLocal.desc
      }

      if (paramsLocal?.sort) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields: paramsLocal.sort,
        }
        delete paramsLocal.sort
      }

      const res = await clientsApi.postCards(clientId, paramsLocal)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClient(id: string) {
    try {
      const res = await clientsApi.getClient(id)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async patchClient(clientId: string, data: Partial<ClientDetail>) {
    try {
      const res = await clientsApi.patchClient(clientId, {
        id: clientId,
        ...data,
      })
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async fetchProducts(clientId: string) {
    try {
      const res = await clientsApi.getProducts(clientId, {
        params: {
          sort: 'product.id',
        },
      })
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchProduct(clientId: string, productId: number) {
    try {
      const res = await clientsApi.api.get<ClientProduct>(
        `/clients/${clientId}/products/${productId}`
      )
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async updateProduct(clientId: string, productId: string | number, data: ClientProduct) {
    try {
      const res = await clientsApi.postProduct(clientId, String(productId), data)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async importEmployees(
    clientId: string,
    type: number,
    body: File,
    showAlertOnError: boolean = true
  ) {
    try {
      const res = await clientsApi.postEmployeesImport(clientId, type, body)

      if (type !== EmployeesImportTemplateActionType.Remove) {
        logSuccess(
          res.data?.messages?.[0].message ?? i18n.t`Súbor ${body.file_name} bol úspešne nahraný`
        )
      }

      return res.data
    } catch (err) {
      if (showAlertOnError) {
        logError(err, i18n.t`Nahratie súboru ${body.file_name} zlyhalo`)
      }

      throw err
    }
  }

  static async importDepartments(clientId: string, body: File, showAlertOnError: boolean = true) {
    try {
      const res = await clientsApi.postDepartmentsImport(clientId, body)
      logSuccess(i18n.t`Súbor ${body.file_name} bol úspešne nahraný`)
      return res.data
    } catch (err) {
      if (showAlertOnError) {
        logError(err, i18n.t`Nahratie súboru ${body.file_name} zlyhalo`)
      }

      throw err
    }
  }

  static async fetchDashboard(clientId: string, productId: number) {
    try {
      const res = await clientsApi.getProductDashboard(clientId, productId)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async saveDeliveryAddress(
    clientId: string,
    branchId: string,
    addressId: string,
    body: DeliveryAddress
  ) {
    try {
      const res: AxiosResponse<Result> = await clientsApi.putDeliveryAddress(
        clientId,
        branchId,
        addressId,
        body
      )
      logSuccess(
        addressId === '0' ? i18n.t`Adresa bola pridaná` : i18n.t`Adresa bola úspešne upravená`
      )
      return res.data
    } catch (err) {
      throw err
    }
  }

  static async putUser(clientId: string, userId: string, body: User) {
    try {
      const res = await clientsApi.putUser(clientId, userId, body)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async fetchUsers(clientId: string, config?: AxiosRequestConfig) {
    try {
      const res = await clientsApi.getUsers(clientId, config)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchAdministrators(clientId: string, config?: AxiosRequestConfig) {
    try {
      const res = await clientsApi.getAdministrators(clientId, config)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchAdministrator(clientId: string, adminId: string, config?: AxiosRequestConfig) {
    try {
      const res = await clientsApi.getAdministrator(clientId, adminId, config)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async putAdministrator(clientId: string, adminId: string, body: Administrator) {
    try {
      const res = await clientsApi.putAdministrator(clientId, adminId, body)
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async deleteAdministrator(clientId: string, adminId: string) {
    try {
      const res = await clientsApi.deleteAdministrator(clientId, adminId)
      logSuccess(itemWasDeleted)
      return res.data
    } catch (err) {
      logError(err, errorDuringDelete)
      throw err
    }
  }

  static async addNewEmployee(clientId: string, body: DeepPartial<Employee>) {
    try {
      const res = await clientsApi.putEmployee(clientId, '0', body)
      logSuccess(i18n.t`Zamestnanec ${body.last_name} ${body.first_name} bol úspešne pridaný.`)
      return res.data as Result
    } catch (err) {
      throw err
    }
  }

  static async deleteEmployeesBulk(clientId: string, ids: string[]) {
    try {
      const res = await clientsApi.postClientEmployeeBulk(clientId, {
        ids,
        operation: CLIENT_EMPLOYEES_BULK_OPERATIONS.Delete,
      })

      logSuccess(res.data?.messages?.[0]?.message || i18n.t`Zamestnaneci boli úspešne odstránený.`)
      return res.data
    } catch (err) {
      logError(err, i18n.t`Pri odstranovaní nastala chyba.`)
      throw err
    }
  }

  static async linkEmployeeToDepartment(
    clientId: string,
    employeeIds: string[],
    departmentId: string
  ) {
    try {
      const res = await clientsApi.postClientEmployeeBulk(clientId, {
        ids: employeeIds,
        object_id: departmentId,
        operation: CLIENT_EMPLOYEES_BULK_OPERATIONS.LinkDepartment,
      })

      logSuccess(res.data?.messages?.[0]?.message || i18n.t`Zamestnanec bol úspešne priradený.`)
      return res.data
    } catch (err) {
      logError(err, i18n.t`Pri priradovaní nastala chyba.`)
      throw err
    }
  }

  static async linkEmployeeToDeliveryAddress(
    clientId: string,
    employeeIds: string[],
    deliveryAddressId: string,
    showAlertOnError: boolean = true
  ) {
    try {
      const res = await clientsApi.postClientEmployeeBulk(clientId, {
        ids: employeeIds,
        object_id: deliveryAddressId,
        operation: CLIENT_EMPLOYEES_BULK_OPERATIONS.LinkDeliveryAddress,
      })
      logSuccess(res.data?.messages?.[0]?.message || i18n.t`Zamestnanec bol úspešne priradený.`)
      return res.data
    } catch (err) {
      if (showAlertOnError) {
        logError(err, i18n.t`Pri priradovaní nastala chyba.`)
      }

      throw err
    }
  }

  static async postEmployeesBulk(
    clientId: string,
    ids: string[],
    operation: CLIENT_EMPLOYEES_BULK_OPERATIONS
  ) {
    try {
      const res = await clientsApi.postClientEmployeeBulk(clientId, {
        ids,
        operation,
      })

      const { data } = res

      const op = i18n._(
        select({
          value: String(operation),
          other: '',
          '4': `aktivovaný`,
          '5': `deaktivovaný`,
        })
      )

      logSuccess(res.data?.messages?.[0]?.message || i18n.t`Zamestnanec bol úspešne ${op}.`)
      return data
    } catch (err) {
      logError(err, i18n.t`Pri operácii nastala chyba.`)
      throw err
    }
  }

  static async fetchAllEmployeesIds(clientId: string, params?: any, config?: AxiosRequestConfig) {
    try {
      let paramsLocal = { ...params }

      if (paramsLocal?.desc) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields_desc: paramsLocal.desc,
        }
        delete paramsLocal.desc
      }

      if (paramsLocal?.sort) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields: paramsLocal.sort,
        }
        delete paramsLocal.sort
      }

      const res = await clientsApi.postEmployees(clientId, paramsLocal, config)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchEmployee(clientId: string, id: string) {
    try {
      const res = await clientsApi.getEmployee(clientId, id)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchEmployees(clientId: string, params?: any, config?: AxiosRequestConfig) {
    try {
      let paramsLocal = { ...params }
      if (paramsLocal?.desc) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields_desc: paramsLocal.desc,
        }
        delete paramsLocal.desc
      }

      if (paramsLocal?.sort) {
        paramsLocal = {
          ...paramsLocal,
          sort_fields: paramsLocal.sort,
        }
        delete paramsLocal.sort
      }

      if (paramsLocal?.filter) {
        paramsLocal = {
          ...paramsLocal,
          filter: paramsLocal.filter.substring(1, paramsLocal.filter.length - 1),
        }
      }
      const res = await clientsApi.postEmployees(clientId, paramsLocal, config)

      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientEmployeesExport(clientId: string, params?: any) {
    try {
      const res = await clientsApi.getClientEmployeesExport(clientId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientEmployeesExportBulk(clientId: string, body: ExportsBulk) {
    try {
      const res = await clientsApi.postClientEmployeesExport(clientId, body, {
        responseType: 'blob',
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientDepartmentsExport(clientId: string, params?: any) {
    try {
      const res = await clientsApi.getClientDepartmentsExport(clientId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientDepartmentsExportBulk(clientId: string, body: ExportsBulk) {
    try {
      const res = await clientsApi.postClientDepartmentsExport(clientId, body, {
        responseType: 'blob',
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientArchiveOrderExport(clientId: string, params?: any) {
    try {
      const res = await clientsApi.getClientArchiveOrderExport(clientId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientCardsExport(clientId: string, params?: any) {
    try {
      const res = await clientsApi.getClientCardsExport(clientId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientCardsExportBulk(clientId: string, body: ExportsBulk) {
    try {
      const res = await clientsApi.postClientCardsExport(clientId, body, {
        responseType: 'blob',
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientBranchesExport(clientId: string, branchId: string, params?: any) {
    try {
      const res = await clientsApi.getClientBranchesExport(clientId, branchId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientBranchesExportBulk(
    clientId: string,
    branchId: string,
    body: ExportsBulk
  ) {
    try {
      const res = await clientsApi.postClientBranchesExport(clientId, branchId, body, {
        responseType: 'blob',
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchClientDocumentsExport(clientId: string, params?: any) {
    try {
      const res = await clientsApi.getClientDocumentsExport(clientId, {
        responseType: 'blob',
        params,
      })
      downloadBlob(res as any)
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async editEmployee(clientId: string, employeeId: string, body: PatchEntity<Employee>) {
    try {
      const res = await clientsApi.patchEmployee(clientId, employeeId, body as any)
      logSuccess(i18n.t`Zamestnanec bol úspešne upravený.`)
      return res.data
    } catch (err) {
      throw err
    }
  }

  static async deleteDeliveryAddress(clientId: string, branchId: string, id: string) {
    try {
      const res = await clientsApi.deleteDeliveryAddress(clientId, branchId, id)
      logSuccess(itemWasDeleted)
      return res.data
    } catch (err) {
      logError(err, errorDuringDelete)
      throw err
    }
  }

  static async fetchDocuments(clientId: string, params?: Record<string, any>) {
    try {
      const res = await clientsApi.getDocuments(clientId, {
        params,
      })

      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchDocument(clientId: string, documentId: string, config?: AxiosRequestConfig) {
    try {
      const res = await clientsApi.getDocument(clientId, documentId, config)
      return res
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async postClientCheck(params: {
    registration_identification: string
    tax_identification: string
  }) {
    try {
      const res = await clientsApi.checkClient({ params })
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async postClientCheckRegistration(params: {
    registration_identification: string
    tax_identification: string
  }) {
    try {
      const res = await clientsApi.checkClientRegistration({ params })
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async resendPromo(params: {
    registration_identification: string
    tax_identification: string
  }) {
    try {
      const res = await clientsApi.resendPromo({ params })
      logSuccess(itemWasSaved)
      return res.data
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async verifyPromo(promo_code: string) {
    try {
      const res = await clientsApi.verifyPromo({
        params: {
          promo_code,
        },
      })
      return res.data
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchEmployeesImportTemplate(clientId: string, type: number) {
    try {
      const res = await clientsApi.api.get<Blob>(
        `/clients/${clientId}/employees/import_template?type=${type}`,
        {
          responseType: 'blob',
        }
      )
      return res
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchAddressesImportTemplate(clientId: string, branchId: string) {
    try {
      const res = await clientsApi.api.get<Blob>(
        `/clients/${clientId}/branches/${branchId}/delivery_addresses/import_template`,
        {
          responseType: 'blob',
        }
      )
      return res
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async fetchDepartmentsImportTemplate(clientId: string) {
    try {
      const res = await clientsApi.api.get<Blob>(
        `/clients/${clientId}/departments/import_template`,
        {
          responseType: 'blob',
        }
      )
      return res
    } catch (err) {
      logError(err, errorDuringFetch)
      throw err
    }
  }

  static async saveEmployeeNotifications(clientId: string, employeeId: string, values: any) {
    try {
      await clientsApi.postClientEmployeeNotifications(clientId, employeeId, values)
      logSuccess(i18n.t`Nastavenie notifikácií bolo uložené`)
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }

  static async postClientRegistration(data: ClientsRegistration) {
    try {
      const response = await clientsApi.postClientRegistration(data)
      const successMessages = get(response, 'data', 'messages') || []

      if (successMessages.length > 0 && successMessages[0].message) {
        return successMessages[0].message
      }

      logSuccess(i18n.t`Registrácia bola úspešná.`)
    } catch (err) {
      logError(err, errorDuringSave)
      throw err
    }
  }
}
