import { AxiosResponse } from 'axios'

export const download = (data: Blob, filename: string) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(data, filename)
  } else {
    // const url = window.URL.createObjectURL(new Blob([data]))
    const url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', filename) // response.headers['content-disposition'].split('filename=')[1]

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank')
    }

    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }
}

/**
 * download blob from xhr req to disk
 */
export function downloadBlob(
  response: AxiosResponse<Blob | BinaryType>,
  defaultName = 'filename.pdf'
) {
  let name = defaultName
  try {
    name = response.headers['content-disposition']
      .split(';')[1]
      .split('=')[1]
      .replace(/"/g, '')
  } catch (err) {
    console.log(err)
  }

  download(new Blob([response.data], { type: response.headers['content-type'] }), name)
}
